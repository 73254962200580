<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          @click="open"
          v-on="on"
        >
          <v-icon>fa fa-images</v-icon>
        </v-btn>
      </template>

      <span>{{'photo_manager'| t}}</span>
    </v-tooltip>
  </div>
</template>

<script lang="babel" type="text/babel">
import photoItemAction from '@/components/photoSelector/action/photoItemAction'
export default {
  mixins: [photoItemAction],
  methods: {
    async open() {
      this.$photoSelector({
        applyCallback: (data) => {
          this.$emit('changePhoto', data.photo)
        },
      })
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
